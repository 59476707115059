<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      @on-complete="formSubmitted"
    >
      <!-- Bank -->
      <tab-content title="Bank" >
        <h3>ธนาคร</h3>
        <b-row>
          <b-col md="12" lg="6" >
            <div >
              <b-form-select
                rules="required"
                v-model="bank"
                :options="options"
                :select-size="13"
              />
            </div>
          </b-col>
          <b-col md="12" lg="6">
            <div>
              {{ banks[bank - 1] }}
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Checkout  -->
      <tab-content title="Checkout" :before-change="validationFormAddress">
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import api from "@/api";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      bank: "",
      selected: null,
      options: [],
      banks: [],
    };
  },
  mounted() {
    this.load_bank();
  },
  methods: {
    async load_bank() {
      api
        .post("get_bank", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.options = response.data.banks;
          this.banks = response.data.banks;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.custom-select {
  width: 320px;

  font-size: 20px;
  border: none;
  margin: 10px;
}
option {
  margin-top: 1em;
}
</style>
